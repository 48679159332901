import * as React from "react"
import { Button, Link, Box, useTheme, Typography } from "@mui/material"

function BuyingOptionsInset({ options }) {
    const theme = useTheme();

    return (
        <Box sx={{mb: 8}}>
            {options.map((option) => (
                <Button key={option.title} component={Link} href={option.url} variant="contained" target="_blank" sx={{ mr: 2 }}>{option.title}</Button>
            ))}
        </Box>
    );
}

export default BuyingOptionsInset;