import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
    fontWeight: '400',
    h1: {
      fontFamily: 'Belleza'
    },
    h2: {
      fontFamily: 'Belleza'
    },
    h3: {
      fontFamily: 'Belleza'
    },
    h4: {
      fontFamily: 'Belleza'
    },
    h5: {
      fontFamily: 'Belleza'
    },
    h6: {
      fontFamily: 'Belleza'
    }
  },
  palette: {
    primary: {
      main: '#7b9b4a',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: 5,
        }
      }
    }
  }
}));

export default theme;