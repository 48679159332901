import { Link as NavLink } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Typography, Link, Rating, Table, TableContainer, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Toc from "../../../../src/components/toc.js";
import * as React from 'react';
export default {
  NavLink,
  getImage,
  GatsbyImage,
  Typography,
  Link,
  Rating,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toc,
  React
};