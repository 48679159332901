import * as React from "react"
import { Link as NavLink } from 'gatsby'
import kebabCase from "lodash/kebabCase"
import { Link, Box, useTheme, List, ListItem, ListItemText, Typography } from "@mui/material"
import { alpha } from "@mui/material/styles"

function Toc({ tableOfContents }) {
    const theme = useTheme();

    return (
        <Box component="details" sx={{ border: '1px solid', borderColor: theme.palette.primary.main, backgroundColor: alpha(theme.palette.primary.main, .2), p: 2 }} open>
            <Typography component="summary" variant="h4">Article Contents</Typography>
            {renderItems(tableOfContents.items, 0)}
        </Box>
    );
}

export default Toc;

function renderItems(items, depth) {
    return (
        <List dense component="div" sx={{ width: '100%', pl: depth+2 }} disablePadding>
            {items.map((item) => (
                <React.Fragment key={item.url}>
                <ListItem disablePadding>
                    <ListItemText>
                    <Link component={NavLink} to={item.url} underline="none">{item.title}</Link>
                    </ListItemText>
                </ListItem>
                { item.items && renderItems(item.items, depth++) }
                </React.Fragment>
            ))}
        </List>
    );
}