import { Link as NavLink } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Typography, Box, Link, Rating } from '@mui/material';
import Toc from "../../../../src/components/toc.js";
import RelatedArticlesInset from "../../../../src/components/related-articles-inset";
import * as React from 'react';
export default {
  NavLink,
  getImage,
  GatsbyImage,
  Typography,
  Box,
  Link,
  Rating,
  Toc,
  RelatedArticlesInset,
  React
};