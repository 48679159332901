import * as React from "react"
import { Link as NavLink } from 'gatsby'
import { Link, Box, useTheme, Typography } from "@mui/material"
import { alpha } from "@mui/material/styles"

function RelatedArticlesInset({ articles }) {
    const theme = useTheme();

    return (
        <Box sx={{ border: '1px solid', borderColor: theme.palette.primary.main, backgroundColor: alpha(theme.palette.primary.main, .2), p: 2, mb: 2 }}>
            <Typography variant="h6">Related Articles</Typography>
            <ul>
            {articles.map((article) => (
                <li key={article.title}><Link component={NavLink} to={article.url}>{article.title}</Link></li>
            ))}
            </ul>
        </Box>
    );
}

export default RelatedArticlesInset;